import routeData from '~/assets/json/route.json'
import { getUnixTime } from 'date-fns'

type Route = {
  path: string
  label: string
  children?: Route[]
}

type FindRoutesArgs = {
  result: Route[]
  path: string
  root: 'lp' | 'company'
}

type useRouterUtils = {
  findRoutes: ({ result, path, root }: FindRoutesArgs) => Route[]
  engageLogin: () => void
}

export const useRouterUtils = (): useRouterUtils => {
  function findRoutes({ result, path, root }: FindRoutesArgs): Route[] {
    let childRoutes: Route[] = []
    if (result.length) {
      const lastRoute = result[result.length - 1]
      if (!lastRoute.children) {
        return result
      }
      childRoutes = lastRoute.children
    } else {
      if (root === 'lp') {
        childRoutes = routeData.lp
      }
      if (root === 'company') {
        childRoutes = routeData.company
      }
    }
    const newRoute = childRoutes.find(route => path.includes(route.path))
    if (!newRoute) {
      return result
    }
    result.push(newRoute)
    return findRoutes({ result, path, root })
  }

  function engageLogin(): void {
    const $env = useRuntimeConfig()
    const url = `${
      $env.public.ENGAGE_URL
    }oauth/authorize?response_type=code&client_id=${
      $env.public.ENGAGE_CLIENT
    }&redirect_uri=${
      $env.public.ENGAGE_CALLBACK_URL
    }/engage/callback/&state=${getUnixTime(new Date())}`
    window.location.href = url
  }

  return {
    findRoutes,
    engageLogin,
  }
}
